import {computed, nextTick, onBeforeUnmount, onMounted, reactive} from "vue";
import {cloneDeep} from "lodash";
import {demoSalePassengersTable} from "@demo/demoSales";
import {useForm} from "@inertiajs/vue3";
import httpClient, {HttpService} from "@services/HttpService";
import {PQ_ROW_MORE_OPTION_LABEL, PQ_ROW_MORE_OPTIONS} from "./constants";
import debounce from "@libs/debounce";
import {getRoute} from "@plugins/useRoutes";
import {useBackendMiddlewarePropsService} from "@services";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

const INITIAL_EMAIL_DATA = {
    client_id: null,
    request_id: [],
    from_emails: null,
    to_emails: null,
    pq_ids: [],
    header_show: null,
    footer_show: null,
    template: '',
    totalCost: 0,
    show_pass: null,
}

export default function usePartialPriceQuotesComponent({props}) {

    const AlertsManager = useAlertsManagerStore()
    const locState = reactive({
        activeRequestTab: 15,

        simpleView: false,
        pqRowsChecked: {},

        pqData: [],

        filterPqsBy: [],

        canShowHeadButtons: false,

        emailModalOpeningId: null,
        emailModalOpeningMulti: false,
        emailModalShow: false,
        emailModalData: {...INITIAL_EMAIL_DATA},

        modalLabelShow: false,
        modalLabelData: null,

        selectedLabelTest: null,

        modalViewDumpShow: false,
        modalViewDumpCode: null,

        modalDuplicatePqShow: false,
        modalDuplicatePqData: false,

        modalDraftModeShow: false,
        modalDraftModeData: null,
        updateMode: false,

    });

    const getSendEmails = computed(() => {
        const count = Object.keys(locState.pqRowsChecked).length
        return {
            show: count > 0,
            count
        }
    })


    const {backendFlashSuccess} = useBackendMiddlewarePropsService()
    const emailModalClearData = () => {
        locState.emailModalData = {...INITIAL_EMAIL_DATA}
        // locState.pqRowsChecked = {}
    }
    const showSendEmailModal = (id = null, btnKey = null) => {
        const pq_ids = id ? [id] : Object.keys(locState.pqRowsChecked)
        if (pq_ids.length == 0) {
            return
        }
        if (id) {
            locState.emailModalOpeningId = id
        } else {
            locState.emailModalOpeningMulti = btnKey
        }

        const form = {
            client_id: props.clientId,
            request_id: props.currentActiveRequestItem?.id,
            pq_ids,
            header_show: true,
            footer_show: true,
        }

        HttpService.previewPQEmail(form).then(({data}) => {
            console.log('dataaaaaaa', data);
            const {from, to, footer_show, header_show, template, totalCost, show_pass} = data
            locState.emailModalData.from_emails = from
            locState.emailModalData.show_pass = show_pass
            locState.emailModalData.to_emails = to
            locState.emailModalData.footer_show = footer_show
            locState.emailModalData.header_show = header_show
            locState.emailModalData.template = template
            locState.emailModalData.client_id = form.client_id
            locState.emailModalData.request_id = form.request_id
            locState.emailModalData.pq_ids = form.pq_ids
            locState.emailModalData.totalCost = totalCost

            console.log('emailModalData', locState.emailModalData);
            setTimeout(() => {
                locState.emailModalShow = true
            }, 100);
        }).finally(() => {
            locState.emailModalOpeningId = null
            locState.emailModalOpeningMulti = false
            locState.pqRowsChecked= {};
            nextTick(() => {
                // Обновляем Vue состояние для всех связанных с чекбоксами элементов
                locState.pqRowsChecked = {};
            }).then(r => console.log('r', r));
        })
    }

    const selectPqRowHandle = (bool, rowId) => {
        if (!bool) {
            if (Object.keys(locState.pqRowsChecked).includes(String(rowId))) {
                delete locState.pqRowsChecked[rowId];
                Object.keys(locState.pqRowsChecked).forEach((key, index) => {
                    locState.pqRowsChecked[key] = index + 1;
                });
            }
            console.log('state', locState.pqRowsChecked);
        } else {
            console.log('stateNew', locState.pqRowsChecked);
            locState.pqRowsChecked[rowId] = Object.keys(locState.pqRowsChecked).length + 1;
        }
        nextTick(() => {
            // Обновляем Vue состояние для всех связанных с чекбоксами элементов
        }).then(r => console.log('r', r));
    };

    const addPnrModal = reactive({
        modalShow: false,
        data: {
            pnrCode: '',
            passengersTable: cloneDeep(demoSalePassengersTable),
        }
    });

    const moreOptionsSelectHandle = (selectedOptionValue, pqRowItem) => {
        if (Object?.values?.(PQ_ROW_MORE_OPTION_LABEL).includes(selectedOptionValue)) {
                locState.modalLabelShow = true
             return
        }

        if (PQ_ROW_MORE_OPTIONS.viewDump === selectedOptionValue) {
            locState.modalViewDumpCode = pqRowItem?.code_dump;
            locState.modalViewDumpShow = true
        }

        if (PQ_ROW_MORE_OPTIONS.hidePQ === selectedOptionValue || selectedOptionValue == 'Unhide PQ') {
            const form = useForm(
                {
                    pq_id: pqRowItem.id,
                    request_id: props.currentActiveRequestItem.id
                }
            )
            form.post(getRoute('requests.requestHidePq'), {
                preserveScroll: true,
                onSuccess() {
                    if (backendFlashSuccess.value) {
                        AlertsManager.add(backendFlashSuccess.value)
                    }
                }
            })
        }

        if(PQ_ROW_MORE_OPTIONS.duplicate === selectedOptionValue ){
            locState.modalDuplicatePqShow = true
            locState.modalDuplicatePqData = pqRowItem
        }
        if(PQ_ROW_MORE_OPTIONS.update === selectedOptionValue ){
            locState.modalDraftModeShow = true
            locState.modalDraftModeData = pqRowItem
            locState.updateMode = true
        }

    }

    const hideModalDraftModeHandle = () => {
        locState.modalDraftModeShow = false
        locState.modalDraftModeData = null
    }
    const hideModalDuplicatePqHandle = () => {
        locState.modalDuplicatePqShow = false
        locState.modalDuplicatePqData = null
    }

    function openDraftModeModal (pqRowItem)  {
        locState.modalDraftModeShow = true
        locState.modalDraftModeData = pqRowItem
    }

    const getAdditionalOptionForMoreDropdown = (pqItemRow) => {
        return PQ_ROW_MORE_OPTION_LABEL.addLabel
    }

    const changeLabelForPqHandle = (value) => {
        locState.selectedLabelTest = locState.selectedLabelTest ? Number(value) + 1 : value
        locState.modalLabelData = null
        locState.modalLabelShow = false;
    }


    const getFilterOptionQnt = computed(() => {
        return props.currentActiveRequestPqs.reduce((acc, item) => {

            if (item.data.send_logs?.length > 0) {
                acc.sent++
            }
            if (item.data?.hidden) {
                acc.hidden++
            }
            return acc
        }, {
            sent: 0,
            hidden: 0
        })
    })

    const getFilteredCurrentActiveRequestPqsPqs = computed(() => {
        if (locState.filterPqsBy?.length > 0) {
            const filterKeys = locState.filterPqsBy?.map(item => item.title?.toLowerCase());
            const res = props.currentActiveRequestPqs.reduce((acc, item) => {
                const {hidden , send_logs} = item.data
                const isHidden = filterKeys.includes('hidden') && hidden
                const isSent = filterKeys.includes('sent') &&  send_logs?.length > 0
                if (isHidden || isSent) {
                    acc.push(item)
                }
                return acc
            }, []);
            return res;
        }
        return props.currentActiveRequestPqs.filter(item => !item.data.hidden);
    })

    const getFilteredCurrentActiveRequestPqsAgent = computed(() => {
        if (locState.filterPqsBy?.length > 0) {
            const filterKeys = locState.filterPqsBy?.map(item => item.title?.toLowerCase());
            const res = props.currentActiveRequestPqs.reduce((acc, item) => {
                const {hidden , send_logs} = item.data
                const isHidden = filterKeys.includes('hidden') && hidden
                const isSent = filterKeys.includes('sent') &&  send_logs?.length > 0
                if (isHidden || isSent) {
                    acc.push(item)
                }
                return acc
            }, []);
            return res;
        }
        return props.currentActiveRequestPqs.filter(item => !item.data.hidden);
    })

    onMounted(() => {
        setTimeout(() => {
            locState.canShowHeadButtons = true
        }, 0);
    })
    onBeforeUnmount(() => {
        locState.canShowHeadButtons = false
    })

    return {
        getSendEmails,
        locState,
        emailModalClearData,
        addPnrModal,
        hideModalDraftModeHandle,
        openDraftModeModal,
        showSendEmailModal, selectPqRowHandle,
        moreOptionsSelectHandle,
        getAdditionalOptionForMoreDropdown,
        changeLabelForPqHandle,
        hideModalDuplicatePqHandle,
        getFilteredCurrentActiveRequestPqsAgent,
        getFilterOptionQnt,
        getFilteredCurrentActiveRequestPqsPqs,
    }
}
