<template>
    <v-dialog v-model="dialog" width="800px" height="454px">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                height="30"
                v-bind="activatorProps"
                color="surface-variant"
                text="Send SMS"
                variant="flat"
            ></v-btn>
        </template>

        <!-- Содержимое диалога: либо контент, либо индикатор загрузки -->
        <v-card v-if="dialog">
            <v-card-title style="background-color: #f8f9fa; height: 60px; align-content: center" class="d-flex justify-space-between align-center">
                <div>
                    Send SMS Template
                </div>
                <v-btn
                    icon="mdi-close"
                    variant="text"
                    @click="dialog = false"
                ></v-btn>
            </v-card-title>

            <!-- Проверяем, идет ли отправка (isLoading). Если да, показываем индикатор загрузки -->
            <v-card-text v-if="!isLoading">
                <!-- Поле "From" -->
                <v-select
                    variant="outlined"
                    density="compact"
                    label="From"
                    v-model="from"
                    title="title"
                    return-object
                    :items="fromOptions"
                    dense
                />

                <!-- Поле "To" (Phone) -->
                <v-select
                    variant="outlined"
                    density="compact"
                    label="Client phone"
                    v-model="to"
                    :items="phones"
                    item-title="phone"
                    item-value="id"
                >
                    <template #item="{ item, props: { onClick } }">
                        <v-list-item :title="item.title" @click="onClick">
                            <template v-slot:append>
                                {{item.type === 1 ? 'Personal' : 'Shared'}} {{item.raw.main ? " Main" : ''}}
                            </template>
                        </v-list-item>
                    </template>
                </v-select>

                <!-- Сообщение -->
                <v-textarea
                    v-model="message"
                    variant="outlined"
                    rows="5"
                    auto-grow
                    :maxlength="1000"
                ></v-textarea>

            </v-card-text>

            <!-- Индикатор загрузки -->
            <v-card-text v-else>
                <v-container class="d-flex justify-center align-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-container>
            </v-card-text>

            <!-- Карточка с кнопками действий (скрыта при isLoading) -->
            <v-card-actions v-if="!isLoading" style="background-color: #f8f9fa; height: 70px; padding: 0 20px 0 20px">
                <div class="text-right grey--text">{{ message.length }} symbols</div>
                <v-spacer></v-spacer>

                <!-- Кнопка "Copy" -->
                <v-btn variant="flat" density="default" color="primary" @click="copyMessage" append-icon="mdi-content-copy">Copy</v-btn>

                <!-- Кнопка "Send" -->
                <v-btn variant="flat"  :disabled="!from || !to" density="default" color="primary" @click="sendSms" append-icon="mdi-send">Send</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import {computed, onMounted, ref, watch} from 'vue'
import {usePage} from "@inertiajs/vue3";
import axios from "axios";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";

const props = defineProps({
    pqId: [Number],
    selectedPq: [Array]
});

const AlertsManager = useAlertsManagerStore();
const dialog = ref(false)
const from = ref('')
const to = ref('')
const isLoading = ref(false); // Добавляем состояние загрузки
const message = ref('')
const selectedAgent = ref('');
const page = usePage();
const pageProps = page.props;
const agents = pageProps.agent;
const client = pageProps.client.data;
const curReqId = pageProps.currentRequest.data.id;
const pqs = pageProps.pqs[curReqId];
const selectedPq = (pqs.find(pq => pq.data.id === props.pqId))?.data;
const price = selectedPq.pq_passengers.sell.adults

message.value = `Hi ${client.first_name}, ${agents[0].first_name} from VIPFARES here. Please find below our best offer for your trip: \n$ ${price}: https://offer.vipfares.com/${selectedPq?.hash_id}`

watch(from, (newValue) => {
    message.value = `Hi ${client.first_name}, ${newValue.agent_name} from VIPFARES here. Please find below our best offer for your trip: \n$ ${price}: https://offer.vipfares.com/${selectedPq?.hash_id}`
})

const dialCodes = ref({});

const loadDialCode = async (dial_code_id) => {
    try {
        const response = await axios.post('https://infohub.vipfares.com/api/get/dial-code', {
            dial_code_id
        });
        return response.data.data.code;
    } catch (error) {
        console.error('Error fetching dial code:', error);
        return '';
    }
};

const loadDialCodes = async () => {
    const requests = agents.map(async agent => {
        dialCodes.value[agent.phone.dial_code_id] = await loadDialCode(agent.phone.dial_code_id);
    });

    await Promise.all(requests);
};

onMounted(() => {
    loadDialCodes();
});

const fromOptions = computed(() => {
    return agents.map(agent => {
        const dialCode = dialCodes.value[agent.phone.dial_code_id] || 'x';
        const phone = `${dialCode} ${agent.phone.phone}`;
        return {
            title: `${agent.first_name} (${phone})`,
            agent_name: agent.first_name,
            value: phone
        };
    });
});

const phones = ref([]);
if (pageProps.client && pageProps.client.data && pageProps.client.data.phone) {
    phones.value = pageProps.client.data.phone.map(p => ({
        id: p.id,
        phone: `${p.dial_code} ${p.phone}`,
        type: p.pivot?.type_id ?? 1,
        main: p.pivot?.main ?? false
    }));
}

const copyMessage = () => {
    navigator.clipboard.writeText(message.value)
    alert('Message copied!')
}

const sendSms = async () => {
    isLoading.value = true; // Включаем индикатор загрузки

    const payload = {
        from: from.value.value,
        to: to.value,
        message: message.value
    };

    try {
        const response = await axios.post('/api/pq/sms', payload);
        console.log('SMS sent successfully:', response.data);
        AlertsManager.add('SMS sent successfully')
    } catch (error) {
        const err = error.response?.data?.error?.message ?? '';
        AlertsManager.add(`Failed to send SMS: ${err}`, 'danger');
    } finally {
        isLoading.value = false; // Отключаем индикатор загрузки после завершения
    }
};
</script>

<style scoped>
.text-right {
    font-size: 12px;
}
</style>
