<template>
    <acms-v-modal
        :show="show"
        size="medium"
        title="Send email"
        @hide="hideHandle"
    >
        <template #content>
            <acms-v-form-field
                label="From"
                :modeSelect="{
                    options:locState.dataForForm.from_emails ?? [],
                    selectedOptionOnInit: 0
                }"
                v-model="locState.form.from_email"
                name="from_email"
                :errors="locState.form.errors"
            />
            <acms-v-form-field
                label="To"
                :modeSelect="{
                    options: getToEmailsOptions,
                    optionsListHasGroups:true,
                    optionsListGroupTitleKey:'group',
                    optionsListGroupItemsKey:'emails',
                    toggleKeyTitle:'email',
                    optionKeyTitle: getOptionContent,
                    selectOptionOnInitInParentFun:setInitialForToEmail,
                    // initialValue:locState.initialValueToEmail,
                    // optionKeyTitle: 'email',
                    outValueKey: 'id',
                    optionTemplate:'multi'
              }"
                name="to_email"
                :errors="locState.form.errors"
                v-model="locState.form.to_email"

            />

            <acms-v-form-field
                label="Copy To"

                :modeSelect="{
                    options: getToEmailsOptions,
                    optionsListHasGroups:true,
                    optionsListGroupTitleKey:'group',
                    optionsListGroupItemsKey:'emails',
                    toggleTagKeyTitle:'email',
                    optionKeyTitle: getOptionContent,
                    outValueKey: 'id',
                    multi:true
              }"
                name="reply_emails"
                :errors="locState.form.errors"
                v-model="locState.form.reply_emails"
            />
            <acms-v-form-field
                label="Subject"
                :errors="locState.form.errors"
                v-model="locState.form.subject"
            />
            <acms-v-label label="Preview email"/>
            <div class="email-content overflow-auto">
                <iframe :src="locState.iframeTemplateUrl" width="100%" height="450" frameborder="0"></iframe>
            </div>
        </template>

        <template #footer>
            <div class="email-template-partials-actions d-flex gap-4">
                <button class="email-template-partials-actions-btn d-flex align-items-center"
                        @click="()=>switchShowEmailPartial('header_show')"
                >
                    <acms-v-icon class="p-2 bg-gray-light me-2 b-r-1"
                                 :name="getPartialIcon('header_show')"/>
                    <span>Header</span>
                </button>
                <button class="email-template-partials-actions-btn d-flex align-items-center"
                        @click="()=>switchShowEmailPartial('footer_show')">
                    <acms-v-icon class="p-2 bg-gray-light me-2 b-r-1"
                                 :name="getPartialIcon('footer_show')"/>
                    <span>Footer</span>
                </button>
                <button class="email-template-partials-actions-btn d-flex align-items-center"
                        @click="()=>switchShowEmailPartial('show_pass')">
                    <acms-v-icon class="p-2 bg-gray-light me-2 b-r-1"
                                 :name="getPartialIcon('show_pass')"/>
                    <span>Show Passport?</span>
                </button>
            </div>
        </template>

        <template #footer-buttons>
            <acms-v-btn title="Send email" class="btn-primary btn-big" :submitting="locState.isSubmitting"
                        @click="sendFormHandle"/>
        </template>
    </acms-v-modal>

</template>

<script>
export default {
    name: "ModalSendEmails"
}
</script>
<script setup="">

import AcmsVFormField from "@ui/FormField/FormField.vue";
import {computed, onMounted, reactive, ref} from "vue";
import AcmsVIcon from "@ui/Icon.vue";
import {useForm} from "@inertiajs/vue3";
import httpService, {HttpService} from "@services/HttpService";
import {getRoute} from "@plugins/useRoutes";
import {useBackendMiddlewarePropsService} from "@services";
import {useAlertsManagerStore} from "@store/plugins/alerts-manager";
import {getSettingsForUseForm} from "@services/FormService";
import {serializeObject} from "@helpers/objectHelpers";
import httpClient from "@services/HttpService";

const emit = defineEmits(['update:show', 'clearEmailData'])
const props = defineProps({
    show: Boolean,
    dataForForm: Object,
    currentActiveRequestItem: Object,
})
const {backendFlashError, backendFlashSuccess} = useBackendMiddlewarePropsService()
// from_emails, to_emails
const testOptions = [{
    group: 'Test group title', emails: [
        {
            id: 11, email: 'daass@mai.ri',
            type: 'Main'
        },
        {
            id: 22, email: 'esssedaass@mai.ri',
            type: 'Personal'
        }
    ],
},
    {
        group: 'Next group title', emails: [
            {
                id: 231, email: 'daass@mai.ri',
                type: 'Main'
            },
            {
                id: 230, email: 'esssedaass@mai.ri',
                type: 'Personal'
            }
        ],
    }
]
const AlertsManager = useAlertsManagerStore()

const hideHandle = () =>{
    emit('update:show', false)
}

const createSubject = (request) => {
    try {
            let airports1;
        switch (props.currentActiveRequestItem.pq_tab_details.type) {
            case 'RT' :  airports1 = request.map((r) => {
                const arrival_city = r.arrival_airport_object.city?.title ?? r.arrival_airport_object.title;
                const departure_city = r.departure_airport_object.city?.title ?? r.departure_airport_object.title;
                return departure_city + " to " + arrival_city;
            })[0]
            break;
             default: airports1 = request.map((r) => {
                const arrival_city = r.arrival_airport_object.city?.title ?? r.arrival_airport_object.title;
                const departure_city = r.departure_airport_object.city?.title ?? r.departure_airport_object.title;
                return departure_city + " to " + arrival_city;
            }).join(' | ');
            break;

        }
        const type = props.dataForForm.pq_ids.length > 1 ? '. Multiple Options' : '';
        return 'VIPFares: Quote from ' + airports1 + ' ' + props.dataForForm.totalCost + type;

    } catch (e) {
        return  ''
    }

}
const locState = reactive({
    dataForForm: props.dataForForm,

    iframeTemplateUrl: null,

    initialValueToEmail:null,
    form: useForm({
        client_id: props.dataForForm.client_id,
        subject: createSubject(props.currentActiveRequestItem.flight_legs),
        request_id: props.dataForForm.request_id,
        pq_ids: props.dataForForm.pq_ids,
        from_email: null,
        to_email: null,
        reply_emails: null,
        footer_show: true,
        header_show: true,
        send_date: null,
        show_pass: props.dataForForm.show_pass,
    }),

    isSubmitting: false,
})
onMounted(()=> {
    createSubject(props.currentActiveRequestItem.flight_legs);
})
const getOptionContent = ({option}) => {

    const getType = (type) => {
        return ` <div class=" ms-2 text-primary-dark" v-if="option?.type">
                    <div> (${type})</div>
                </div>`
    }
    return `<div class="d-flex"><div class="title"> ${option.email}</div>${option?.type ? getType(option?.type) : ''}<div>`
}

const getToEmailsOptions = computed(() => {
    if (locState.dataForForm?.to_emails) {
        return Object?.values(locState.dataForForm.to_emails)
    }
    return testOptions
})

const sendFormHandle = () => {
    const formSettings = getSettingsForUseForm()
    locState.isSubmitting = true
    locState.form.transform((data => {

        return {
            ...data,
            reply_emails: (data.reply_emails ?? []).map(item => item.id)
        }
    })).post(getRoute('pq.send'), {
        ...formSettings,
        onSuccess() {
            if (backendFlashError.value) {
                locState.isSubmitting = false
            }
            locState.isSubmitting = false

            if (backendFlashSuccess.value?.message) {
                AlertsManager.add(backendFlashSuccess.value?.message)
                emit('clearEmailData', false)
                emit('update:show', false)
            }
        },
        onError() {
            AlertsManager.add('Email form has errors', 'danger')
            locState.isSubmitting = false
        }
    })
}
const getNewTemplateUrl = () => {
    const object = {
        client_id: props.dataForForm.client_id,
        request_id: props.dataForForm.request_id,
        pq_ids: props.dataForForm.pq_ids,
        header_show: locState.form.header_show ? 1 : 0,
        footer_show: locState.form.footer_show ? 1 : 0,
    }
    console.log('');
    return getRoute('pq.generate-view') + '?' + serializeObject(object)
}

const sendRequestToGetNewTemplate = () => {
    locState.iframeTemplateUrl = getNewTemplateUrl()
}
const switchShowEmailPartial = (key) => {
    locState.form[key] = !locState.form[key];
    sendRequestToGetNewTemplate()
};

const setShowPass = () => {
    console.log('asda');
}
const getPartialIcon = (key) => locState.form[key] ? 'eye' : 'eye-off';

const setInitialForToEmail = (methodToSelectValue) => {
    const emailsArray = getToEmailsOptions.value.reduce((acc, item) => {
        acc = [...acc, ...item.emails]
        return acc
    }, [])
    const find = emailsArray.find(item => item.type == 'Main/Personal')
    if (find) {
        methodToSelectValue(find)
    }
}

onMounted(() => {
    console.log('here', props);
    locState.iframeTemplateUrl = getNewTemplateUrl()
})

</script>

<style scoped lang="scss">
.email-content {
    max-height: 459px;
    overflow: auto;
}

.btn-big {
    padding: 8px 16px;
}
</style>
